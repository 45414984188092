import React from "react";
import ReactMarkdown from "react-markdown";
import LazyLoad from 'react-lazyload';
import './production-options.css'

const ProductionOptions = (props) => {
    const {
        VerticalTitle,
        Subtitle,
        Title,
        BottomSectionPO,
        TopSectionPO
    } = props;

    return (
        <div className="production-options">
            <div className="container">
                <h2 className="production-options__title" dangerouslySetInnerHTML={{__html:Title}}></h2>
                <h3 className="production-options__subtitle" dangerouslySetInnerHTML={{__html:Subtitle}} />
                <span className="vertical-title">{VerticalTitle}</span>
                {TopSectionPO.Title && (
                    <p className="production-options__disclaimer">{TopSectionPO.Title}</p>
                )}
                <div className="production-options__top">
                {TopSectionPO.OptionColumnPo.map((column, index) =>(
                    <div key={index}>
                        <LazyLoad>
                            <img src={column.Image.url} alt={column.Image.alternativeText} />
                        </LazyLoad>
                        <h4>{column.Title}</h4>
                        {column.Subtile && (
                            <h5>{column.Subtile}</h5>
                        )}
                        <p>{column.Content}</p>                        
                    </div>
                ))}
                </div>
                <LazyLoad>
                    <img  className="production-options__bottom-img" src={BottomSectionPO.Image.url} alt={BottomSectionPO.Image.alternativeText} />
                </LazyLoad>
                <div className="production-options__bottom">
                    <div>
                        <h4>{BottomSectionPO.Title}</h4>
                        <ReactMarkdown source={BottomSectionPO.Content} />
                    </div>
                    <div>
                        <ReactMarkdown source={BottomSectionPO.Column2} />
                    </div>
                    <div>
                        <ReactMarkdown source={BottomSectionPO.Column3} />
                    </div>
                </div>
            </div>
        </div>
    )};

export default ProductionOptions;