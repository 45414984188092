import React from "react";
import ReactMarkdown from "react-markdown";
import LazyLoad from 'react-lazyload';
import './video-thumb.css';
const play = require('../../images/icons/play.png');

class VideoThumb extends React.Component {
    player = undefined;
    constructor(props) {
        super(props);
        this.state = {
            hideThumbnail: false
        }
    }

    hideThumb = (el) => {
        this.setState({hideThumbnail: true})
        console.log(this.player)
        this.player.playVideo();
    }

    setupYT = (el) => {
        if(el && el.dataset.videoCode) {
            const videoCode = el.dataset.videoCode.match(/src="(.*?)"/g)[0].replace(/src=/gm, "").replace('https://www.youtube.com/embed/','');
            this.waitTilYouTube(el, JSON.parse(videoCode));
        }
    }

    waitTilYouTube = (el, videoCode) => {
        const _this = this;
        setTimeout(() => {
            if(window.YT) {
                window.YT.ready(() => {
                    _this.player = new window.YT.Player(el, {
                        videoId: videoCode
                    });
                });
            } else {
                _this.waitTilYouTube(el, videoCode);
            }
        }, 150);
    }

    render() {
        const {
            Title,
            Content,
            VideoEmbed,
            VideoThumbnail,
            BackgroundImage
        } = this.props;

        return (
            <div className="video-thumb" style={BackgroundImage && {backgroundImage: `url(${BackgroundImage?.url.replace('https://res.cloudinary.com/dfilm3vuy/image/upload/', 'https://res.cloudinary.com/dfilm3vuy/image/upload/f_auto,q_auto/')})`}}>
                <div className="container">
                    <div className="video-thumb__content">
                        {(VideoThumbnail || VideoEmbed) && <div onClick={() => this.hideThumb()}  className="video-thumb__video">
                            {!this.state.hideThumbnail && (
                            <div className="video-thumbnail">
                                {(VideoEmbed && VideoThumbnail) && <img src={play} className="video-btn" />}
                                <LazyLoad><img src={VideoThumbnail?.url.replace('https://res.cloudinary.com/dfilm3vuy/image/upload/', 'https://res.cloudinary.com/dfilm3vuy/image/upload/f_auto,q_auto/')} alt={VideoThumbnail?.alternativeText} /></LazyLoad>
                            </div>
                            )}
                            <div className="video-thumb__video-wrapper">
                                <div ref={el => this.setupYT(el)}  data-video-code={VideoEmbed} ></div>
                            </div>
                        </div>
                        }
                        <div>
                            <h2 className="video-thumb__title" dangerouslySetInnerHTML={{__html:Title}}></h2>
                            <ReactMarkdown 
                                source={Content}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default VideoThumb;