import React from "react";
import ReactMarkdown from "react-markdown"
import LazyLoad from 'react-lazyload';
import './performance-map.css';

const PerformanceMap = (props) => {
    const {
        Title,
        Content,
        vertical_title,
        map_title,
        map_img,
        location_list
    } = props;

    const switchImage = (ev) => {
        document.querySelector('.performance-map__map').src = ev.target.dataset.img;
    }

    const switchBack = () => {
        document.querySelector('.performance-map__map').src = document.querySelector('.performance-map__map').dataset.img;
    }

    return (
        <div className="performance-map">
            <div className="container">
                <span className="vertical-title">{vertical_title}</span>
                <div className="performance-map__content">
                    <div className="performance-map__text">
                        <h2 className="performance-map__title" dangerouslySetInnerHTML={{__html:Title}}></h2>
                        <ReactMarkdown
                            source={Content}
                        />
                    </div>
                    <div>
                        <h3 className="performance-map__subtitle" dangerouslySetInnerHTML={{__html:map_title}}></h3>
                        <div className="performance-map__map-selector">
                            <div>
                            {map_img && (
                                <LazyLoad><img src={`${map_img.url}`} data-img={`${map_img.url}`} className="performance-map__map" alt={map_img.alternativeText} width={map_img.width} height={map_img.height} /></LazyLoad>
                            )}
                            </div>
                            <ul>
                                {location_list.map(location => (
                                    <li key={location.id}>
                                        <a href={location.link} 
                                            onMouseEnter={switchImage} 
                                            onMouseLeave={switchBack} 
                                            data-img={location.image && `${location.image.url}`}>
                                                {location.location}
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PerformanceMap;