import React from "react";
import { StaticQuery, graphql } from "gatsby";
import LazyLoad from 'react-lazyload';
import './songlist.css';

class Songlist extends React.Component {
    genre;
    constructor(props) {
        super(props);
        this.state = {
            selectedId: null
        }

        this.includeList = props.include_list? props.include_list.split(',') : [];
        this.excludeList = props.exclude_list? props.exclude_list.split(',') : [];
    }

    componentDidMount() {
        this.setId(this.genre[0].id);
    }

    setId(id) {
        this.setState({
            selectedId: id
        })
    }

    render() {

        return (
            <StaticQuery
              query={graphql`
                query {
                  strapi {
                    songList {
                        id
                        Title
                        subtitle
                        vertical_title
                        genre {
                            id
                            Title
                            not_by_default
                            song {
                                id
                                song
                                artist
                            }
                        }
                        background_img {
                          url
                        }
                    }
                  }
                }
              `}
              render={
                data => {
                const {
                    background_img,
                    Title,
                    subtitle,
                    vertical_title,
                } = data.strapi.songList;

                let {
                    genre
                } = data.strapi.songList;

                genre = genre.filter(entity => !entity.not_by_default || this.includeList.filter(id => Number(id) === Number(entity.id).length));
                this.genre = this.excludeList.length ? genre.filter(entity => this.excludeList.filter(id => Number(id) !== Number(entity.id)).length) : genre;

                return  (
                <div className="songlist" style={{backgroundImage: `url(${background_img.url})`}}>
                    <div className="songlist__container container">
                        <h2 className="songlist__title" dangerouslySetInnerHTML={{__html:Title}}></h2>
                        <p className="songlist__subtitle">{subtitle}</p>
                        <span className="vertical-title songlist__vertical">{vertical_title}</span>
                        <div className="songlist__songs">
                            <ul>
                                {genre.map(category => (
                                <li className={(category.id === this.state.selectedId) && 'active'} key={category.id}>
                                    <button onClick={() => this.setId(category.id)}>{category.Title}</button>
                                </li>
                                ))}
                            </ul>
                            {genre.map(category => {
                                let count = 0;
                                return (
                                <ol key={category.id} style={{display: category.id === this.state.selectedId ? "" : "none"}}>
                                    {category.song.map(song => {
                                        count++;
                                        return (
                                        <li key={song.id}>
                                            <span className="songlist__number">{count.length > 1 ? count: `0${count}`}</span>
                                            <span>
                                                {song.song}
                                                <em> - {song.artist}</em>
                                            </span>
                                        </li>
                                    )})}
                                </ol>
                            )})
                        }
                        </div>
                    </div>
                </div>

            )}}
            />
        );
    }
}

export default Songlist;