import React from "react";
import ReactMarkdown from "react-markdown";
import LazyLoad from 'react-lazyload';
import './video-collection.css'
const play = require('../../images/icons/play.png');

class VideoCollection extends React.Component {
    player = {};
    videos = [];
    constructor(props) {
        super(props);
        this.state = {
            hideThumbnail: {},
        }
    }

    componentDidMount() {
        this.waitTilYouTube();
    }

    waitTilYouTube = (el, videoCode) => {
        const _this = this;
        setTimeout(() => {
            if(window.YT) {
                window.YT.ready(() => {
                    _this.videos.forEach(video => {
                        _this.player[video.id] = new window.YT.Player(video.el, {
                            videoId: video.videoCode
                        });
                        this.setState({
                            hideThumbnail: {
                                [video.id]: false,
                                ...this.state.hideThumbnail
                            } 
                        })
                    });
                });
            } else {
                _this.waitTilYouTube(el, videoCode);
            }
        }, 150);
    }

    hideThumb = (id) => {
        this.setState({hideThumbnail: {
            ...this.state.hideThumbnail,
            [id]: true
        }})
        this.player[id].playVideo();
    }

    setupYT = (el) => {
        if(this.videos.length) this.videos = [];
        if(el && el.dataset.videoCode) {
            const videoCode = el.dataset.videoCode.match(/src="(.*?)"/g)[0].replace(/src=/gm, "").replace('https://www.youtube.com/embed/','');
            this.videos.push({
                id: el.dataset.id,
                videoCode: JSON.parse(videoCode),
                el
            });
        }
    }

    render() {
        const {
            Content,
            Title,
            VideoRow
        } = this.props;

        return (
            <div className="video-collection">
                <div className="container">
                    <h2 className="video-collection__title" dangerouslySetInnerHTML={{__html: Title}}  />
                    <div className="video-collection__content" >
                    <ReactMarkdown 
                        source={Content}
                    />
                    </div>
                    {VideoRow?.map(video => (
                        <div key={video.id} className="video-collection__row">
                            <div key={video.id} className="video-collection__video-container" onClick={() => this.hideThumb(video.id)}>
                                {!this.state.hideThumbnail[video.id] && (
                                <div className="video-thumbnail">
                                    {(video.VideoEmbed && video.VideoThumbnail) && <img src={play} className="video-btn" />}
                                    <LazyLoad><img src={video.VideoThumbnail?.url.replace('https://res.cloudinary.com/dfilm3vuy/image/upload/', 'https://res.cloudinary.com/dfilm3vuy/image/upload/f_auto,q_auto/')} alt={video.VideoThumbnail?.alternativeText} /></LazyLoad>
                                </div>
                                )}
                                <div className="video-carousel__item">
                                    <div data-id={video.id} ref={el => this.setupYT(el)}  data-video-code={video.VideoEmbed} ></div>
                                </div>
                            </div>
                            <div className="video-collection__row-content">
                                <h3 className="video-collection__subtitle" dangerouslySetInnerHTML={{__html: video.Title}} />
                                <p  dangerouslySetInnerHTML={{__html: video.Content}} />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
    )}};

export default VideoCollection;