import React from "react";
import ReactMarkdown from "react-markdown"
import LazyLoad from 'react-lazyload';
import './image-right-text.css';


const ImageRightText = (props) => {
    const {
        vertical_title,
        Title,
        Content,
        primary_img
    } = props;
    return (
    <div className="image-right-text">
        <div className="container image-right-text__container">
            <div className="image-right-text__text">
                <h3 className="vertical-title image-right-text__vertical">{vertical_title}</h3>
                <h3 className="image-right-text__title" dangerouslySetInnerHTML={{__html:Title}}></h3>
                <ReactMarkdown
                    source={Content}
                />
            </div>
            <div className="image-right-text__img">
                <LazyLoad><img src={`${primary_img?.url.replace('https://res.cloudinary.com/dfilm3vuy/image/upload/', 'https://res.cloudinary.com/dfilm3vuy/image/upload/f_auto,q_auto/')}`} alt={primary_img?.alternateText} /></LazyLoad>
            </div>
        </div>
    </div>
)}

export default ImageRightText;