import React from "react";
import LazyLoad from 'react-lazyload';
import './gallery-collage.css';
const arrowLeft = require('../../images/icons/gallery-arrow-left.png');
const arrowRight = require('../../images/icons/gallery-arrow-right.png');

const GalleryCollage = (props) => {
    const {
        VerticalTitle,
        Images,
        link,
        link_title
    } = props;

    return (
        <div className="gallery-collage">
            <div className="container">
                <div className="vertical-title">{VerticalTitle}</div>
                <ul className="gallery-collage__images" >
                    {Images.map(img => (
                        <li key={img.id}><LazyLoad><img src={`${img.url}`} alt={img.alternativeText} width={img.width} height={img.height} /></LazyLoad></li>
                    ))}
                </ul>
                <a href={link} className='button'>{link_title}</a>
            </div>
        </div>
    );
} 
export default GalleryCollage;
