import React from "react";
import './review-card.css';

const fiveStars = require('../../images/icons/5stars.png');

const ReviewCard = (props) => {
    const {content, event_type} = props;
    return (
    <div className="review-card" data-event={event_type ? event_type.name : ''}>
        <p><img src={fiveStars} alt='Five Stars' height="29" /></p>
        <p>{content}</p>
    </div>
    )
}

export default ReviewCard;