import React from "react";
import './contact-block.css';
import { StaticQuery, graphql } from "gatsby";
import ReactMarkdown from "react-markdown";
import TextField from "../fields/text-field";
import EmailField from "../fields/email-field";
import NumberField from "../fields/number-field";
import DateField from "../fields/date-field";
import DropDownField from "../fields/drop-down-field";
import TextAreaField from "../fields/text-area-field";
import TelField from "../fields/tel-field";

class ContactBlock extends React.PureComponent {
    constructor(props) {
        super(props);
        
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

        typeof window !== "undefined" && window.emailjs.init('ylBK3_Tpr-f15RQij');
    }
    
    handleChange(event) {
        this.setState({[event.target.dataset.name]: event.target.value});
    }
    
    handleSubmit(e) {
        e.preventDefault();

        document.querySelector('.loading').style.display = 'block';
        
        var target = e.target;
        var data = new FormData(e.target);
        const formDataObj = {};
        data.forEach((value, key) => (formDataObj[key] = value));

        window.emailjs.sendForm('service_p3b6qxj', 'template_jamhot', target)
        .then(function() {
            document.querySelector('#contact_form').style.display = 'none';
            document.querySelector('.loading').style.display = 'none';
            document.querySelector('#thanks').style.display = 'block';
            alert("Your form has been succesfully submitted");
        }, function(error) {
            alert('FAILED...', error);
        });
        
        return false;
    }

    getField(field) {
        switch(field?.__typename.replace('Strapi_ComponentFormComponents', '')) {
            case 'DropDownField': return <DropDownField {...field} />;
                break;
            case 'DateField': return <DateField {...field} />;
                break;
            case 'TextareaField': return <TextAreaField {...field} />;
                break;
            case 'NumberField': return <NumberField {...field} />;
                break;
            case 'TelField': return <TelField {...field} />;
                break;
            case 'EmailField': return <EmailField {...field} />;
                break;
            default: return (<TextField {...field} />);
        }
    }
    
    render() {
        const {
            ContactFormTitle,
            Content,
            SocialTitle,
            Title,
            BackgroundImage,
            form_block
        } = this.props;
        const halfway = Math.round(form_block.FormBlocks.length / 2);
        const formBlock = [[], []];
        let formColumn = 0;

        return (
            <StaticQuery query={graphql`
            query {
                strapi {
                    social {
                        facebook_link
                        instagram_link
                        linkedin_link
                        telephone
                        email
                        youtube_link
                    }
                }
            }`}
            render={
                data => { 
                    const {
                        facebook_link,
                        instagram_link,
                        linkedin_link,
                        youtube_link,
                        email,
                        telephone
                    } = data.strapi.social;
                    return (
                        <div className="contact-block" style={{backgroundImage: `url(${BackgroundImage?.url.replace('https://res.cloudinary.com/dfilm3vuy/image/upload/', 'https://res.cloudinary.com/dfilm3vuy/image/upload/f_auto,q_auto/')})`}}>
                        <div className="container">
                        <div className="contact-block__content">
                            <div className="contact-block__text">
                            <h2 className="contact-block__title">{Title}</h2>
                            <ReactMarkdown
                                source={Content}
                                className="contact-block__content-box"
                                />
                            </div>
                            <div className="contact-block__form">
                            <h3 className="contact-block__form-title" dangerouslySetInnerHTML={{__html: ContactFormTitle}}/>
                            <div id="thanks">
                                <p>Thanks for sending your message</p>
                            </div>
                            {form_block && (
                                <form id="contact_form" onSubmit={this.handleSubmit} className="contact-block__form-form">
                                    <div className="loading"></div>
                                    
                                {form_block.FormBlocks.map((field, index) => {

                                    if (index === halfway + 1) {
                                        formColumn++;
                                    }
                                    formBlock[formColumn].push(this.getField(field));
                                })}
                                {formBlock.map((block, index) => (
                                    <div>
                                        {block}
                                        {index === formColumn && (
                                            <div><button class="fserv-button-submit contact-block__form-button" type="submit">Submit</button></div>
                                        )} 
                                    </div>
                                ))}
                                      
                                </form>
                            )}
                           
                        <p>&nbsp;</p>
                        </div>
                        </div>
                        <div className="contact-block__social">
                        <h3 className="contact-block__social-title">{SocialTitle}</h3>
                        <ul>
                    {facebook_link && (<li>
                        <a href={facebook_link} className="contact-block__social-facebook contact-block__social-icon">Facebook</a>
                        </li>)}
                        {instagram_link && (<li>
                            <a href={instagram_link} className="contact-block__social-instagram contact-block__social-icon">Instagram</a>
                            </li>)}
                            {youtube_link && (<li>
                                <a href={youtube_link} className="contact-block__social-youtube contact-block__social-icon">YouTube</a>
                                </li>)}
                                {linkedin_link && (<li>
                                    <a href={linkedin_link} className="contact-block__social-linkedin contact-block__social-icon">LinkedIn</a>
                                    </li>)}
                                    </ul>
                                    </div>
                                    </div>
                                    </div>
                                    )}
                                }
                                />
                                );
                            }
                        }
                        
                        export default ContactBlock;