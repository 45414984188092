import React from "react";
import LazyLoad from 'react-lazyload';
import './carousel.css';

class Carousel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            animateLeft: false,
            prepareRight: false,
            animateRight: false,
            items: props.carousel_item
        }
    }

    moveLeft() {
        if (this.state.animateLeft || this.state.animateRight) return;

        const items = [...this.state.items];
        items.unshift(items[items.length - 1]);
        this.setState({
            prepareRight: true,
            items: items
        });
        items.pop();
        setTimeout(() => {
            this.setState({
                animateRight: true
            });
        }, 1);
        setTimeout(() => {
            this.setState({
                prepareRight: false,
                animateRight: false
            });
        }, 500);
    }

    moveRight() {
        if (this.state.animateLeft || this.state.animateRight) return;
        this.setState({animateLeft: true});
        setTimeout(() => {
            const items = [...this.state.items];
            items.push(items.shift());
            this.setState({
                items: items,
                animateLeft: false
            });
        }, 500);
    }

    showButtons(carouselLength) {
        let items = 3;

        if(typeof window === 'undefined') return carouselLength > items;

        if (window.screen.width < 1080 && window.screen.width > 700) {
            items = 2;
        } else if(window.screen.width < 700) {
            items = 1;
        }
        return carouselLength > items;
    }

    render () {
        const {
            Title,
            subtitle,
            background_color,
            background_img,
            carousel_item,
            read_more_link,
            read_more_text
        } = this.props;
        return (
        <LazyLoad>
        <div className="carousel" style={{
            backgroundColor: background_color,
            backgroundImage: `url(${background_img?.url.replace('https://res.cloudinary.com/dfilm3vuy/image/upload/', 'https://res.cloudinary.com/dfilm3vuy/image/upload/f_auto,q_auto/')})`
        }}>
            <h2 className="carousel__title" dangerouslySetInnerHTML={{__html:Title}}></h2>
            <p>{subtitle}</p>

            <div className="carousel__container">
                <div className="carousel__list-container">
                    <ul className={`carousel__list ${this.state.animateLeft ? 'move-left' : ''} ${this.state.animateRight ? 'animate-right' : ''} ${this.state.prepareRight ? 'move-right' : ''}` }>
                        {this.state.items?.map(item => (
                            <li key={item.id}>
                                <div className="carousel__item" style={{
                                    backgroundImage: `url(${item.image?.url.replace('https://res.cloudinary.com/dfilm3vuy/image/upload/', 'https://res.cloudinary.com/dfilm3vuy/image/upload/f_auto,q_auto/')})`
                                }}>
                                    <a href={item.link} />
                                    <div className="carousel__item-text">
                                        <h3 className="carousel__item-title">{item.title}</h3>
                                        {item.text && (<p className="carousel__item-subtext">{item.text}</p>)}
                                    </div>
                                    <img src={`${item.image?.url.replace('https://res.cloudinary.com/dfilm3vuy/image/upload/', 'https://res.cloudinary.com/dfilm3vuy/image/upload/f_auto,q_auto/')}`} alt={item.image?.alternativeText} />
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
                {this.showButtons(carousel_item.length) && (
                    <>
                        <button onClick={() => this.moveLeft()} className="carousel__left">Show Previous</button>
                        <button onClick={() => this.moveRight()} className="carousel__right">Show Next</button>
                    </>
                )}
            </div>
            {read_more_link && (<a href={read_more_link} className="button">{read_more_text}</a>)}
        </div>
        </LazyLoad>
    )}
};

export default Carousel;