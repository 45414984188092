import React from "react";
import LazyLoad from 'react-lazyload';
import './gallery-masonry.css';
import Masonry from 'react-masonry-component';

const GalleryMasonry = (props) => {
    const {
        images
    } = props;
    let masonry;

    const selected = (ev) => {
        ev.preventDefault();
        if(document.querySelector('.gallery-masonry__item.selected')) {
            document.querySelector('.gallery-masonry__item.selected').className = 'gallery-masonry__item';
        }
        ev.target.parentElement.className = 'gallery-masonry__item selected';

        masonry.layout();
    }

    return (
        <div className="gallery-masonry">
            <div className="container">
                <Masonry 
                    className={'full-reviews__reviews masonry'}
                    ref={function(c) {masonry = masonry || c.masonry;}}
                >
                {images.map(img => (
                    <div key={img.id} className="gallery-masonry__item">
                        <a href="#" onClick={selected} style={{backgroundImage: `url(${img.url})`}}>
                            <img src={`${img.url}`} alt={img.alternativeText} width={img.width} height={img.height} />
                        </a>
                    </div>
                ))}
                </Masonry>
            </div>
        </div>
    );
}

export default GalleryMasonry;