import React from "react";
import { StaticQuery, graphql } from "gatsby";
import LazyLoad from 'react-lazyload';
import './band-line-up.css';

const BandLineUp = (props) => {
    let {
        include_list,
        exclude_list
    } = props;

    const includeList = include_list ? include_list.split(',') : [];
    const excludeList = exclude_list ? exclude_list.split(',') : [];
    return (
        <StaticQuery
          query={graphql`
            query {
              strapi {
                bandLineUp {
                    id
                    title
                    subtitle
                    background_image {
                        url
                    }
                    subliminal_text
                    band_entry {
                        id
                        amount_of_members
                        not_by_default
                        members {
                            member
                        }
                    }
                }
              }
            }
          `}
          render={
            data => {
                const {
                    title,
                    subtitle,
                    subliminal_text,
                    background_image
                } = data.strapi.bandLineUp;

                let {
                    band_entry
                } = data.strapi.bandLineUp;

                band_entry = band_entry.filter(entity => !entity.not_by_default ||  includeList.filter(id => Number(id) === Number(entity.id)).length);
                band_entry = excludeList.length ? band_entry.filter(entity => excludeList.filter(id => Number(id) !== Number(entity.id)).length) : band_entry;

            return (
            <div className="band-line-up">
                <div className="band-line-up__header">
                    <div className="band-line-up__text">
                        <span className="vertical-title">Line Up</span>
                        <h2 className="band-line-up__title">{title}</h2>
                        <p>{subtitle}</p>
                    </div>
                    <LazyLoad>
                        <img src={`${background_image.url}`} className="band-line-up__img" alt={background_image.alternativeText} />
                    </LazyLoad>
                    <span className="band-line-up__opacity-text">{subliminal_text}</span>
                </div>
                <div className="band-line-up__options">
                    {band_entry.map(band => (
                        <div key={band.id}>
                            <span>{band.amount_of_members}</span>
                            <h3>{band.amount_of_members} Piece <em>Band</em></h3>
                            <ul>
                                {band.members.map((val, index) => (
                                    <li key={index}>{val.member}</li>
                                ))}
                            </ul>
                        </div>
                    ))}
                </div>
            </div>

        )}}
    />
)};

export default BandLineUp;