import React from "react";
import ReactMarkdown from "react-markdown"
import LazyLoad from 'react-lazyload';
import './full-performance-map.css';

class FullPerformanceMap extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            MapLocation: null,
            Location: null
        }
    }

    MapLocationClick = (id) => {
        this.setState({
            MapLocation: this.state.MapLocation !== id ? id : null
        })
    }
    
    LocationClick = (id) => {
        this.setState({
            Location: this.state.Location !== id ? id : null
        })
    }

    render() {
        const {
            vertical_title,
            Title,
            Content,
            MapLocations,
            map_img
        } = this.props;

        const switchImage = (ev) => {
            document.querySelector('.full-performance-map__map').src = ev.target.dataset.img;
        }

        const switchBack = () => {
            document.querySelector('.full-performance-map__map').src = document.querySelector('.full-performance-map__map').dataset.img;
        }

        return (
            <div className="full-performance-map">
                <div className="container">
                    <span className="vertical-title">{vertical_title}</span>
                    <div className="full-performance-map__content">
                        <h2 className="full-performance-map__title" dangerouslySetInnerHTML={{__html:Title}}></h2>
                        <div>
                            <ReactMarkdown
                                source={Content}
                            />
                        </div>
                    </div>
                    <div className="full-performance-map__content">
                        <ul className="full-performance-map__list">
                            {MapLocations.map(MapLocation => (
                                <li className={`${(this.state.MapLocation === MapLocation.id) && 'active'}`} key={MapLocation.id}>
                                    <h3 onClick={() => this.MapLocationClick(MapLocation.id)}>{MapLocation.Region}</h3>
                                    {MapLocation.location_list.map(location => (
                                        <ul>
                                        {location.event_type.map(event => (
                                            <li className={`${(this.state.Location === event.id) && 'active'}`}>
                                                <h4 onClick={() => this.LocationClick(event.id)}>{event.EventTitle}</h4>
                                                <ul>
                                                {event.location_list.map(loc => (
                                                    <li>
                                                        <a href={loc.link} 
                                                            onMouseEnter={switchImage} 
                                                            onMouseLeave={switchBack} 
                                                            data-img={loc.image && `${loc.image.url}`}>
                                                                {loc.location}
                                                        </a>
                                                    </li>
                                                ))}
                                                </ul>
                                            </li>
                                        ))}
                                        </ul>
                                    ))}
                                </li>
                            ))}
                        </ul>
                        <div>
                        {map_img && (
                            <LazyLoad><img src={`${map_img.url}`} data-img={`${map_img.url}`} className="full-performance-map__map" alt={map_img.alternativeText} width={map_img.width} height={map_img.height} /></LazyLoad>
                        )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default FullPerformanceMap;