import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Hero from "../components/hero/hero"
import LogoRow from "../components/logo-row/logo-row"
import AboutUs from "../components/about-us/about-us"
import BandLineUp from "../components/band-line-up/band-line-up"
import ImageRightText from "../components/image-right-text/image-right-text"
import ImageLeftText from "../components/image-left-text/image-left-text"
import Carousel from "../components/carousel/carousel"
import BookJamHot from "../components/book-jam-hot/book-jam-hot"
import VideoCarousel from "../components/video-carousel/video-carousel"
import Songlist from "../components/songlist/songlist"
import Gallery from "../components/gallery/gallery"
import PerformanceMap from "../components/performance-map/performance-map"
import Reviews from "../components/reviews/reviews"
import FullWidthBook from "../components/full-width-book/full-width-book"
import HeroImage from "../components/hero-image/hero-image"
import FullReviews from "../components/full-reviews/full-reviews"
import GalleryMasonry from "../components/gallery-masonry/gallery-masonry"
import { Helmet } from "react-helmet"
import GalleryCollage from "../components/gallery-collage/gallery-collage"
import TextBlock from "../components/text-block/text-block"
import TextImageText from "../components/text-image-text/text-image-text"
import ProductionOptions from "../components/production-options/production-options"
import VideoCollection from "../components/video-collection/video-collection"
import FullPerformanceMap from "../components/full-performance-map/full-performance-map"
import VideoOffset from "../components/video-offset/video-offset"
import TwoColumnTitle from "../components/two-column-title/two-column-title"
import VideoThumb from "../components/video-thumb/video-thumb"
import ContactBlock from "../components/contact-block/contact-block"

export const query = graphql`
  query PageQuery($id: ID!) {
    strapi {
        page(id: $id) {
            id
            slug
            meta_description
            meta_robots
            og_fb_image {
                url
            }
            og_twitter_image {
                url
            }
            Title
            Content {
              ... on Strapi_ComponentPageComponentsContactBlock {
                id
                ContactFormTitle
                Content
                SocialTitle
                Title
                BackgroundImage {
                  alternativeText
                  height
                  url
                  width
                }
                form_block {
                  Title
                  FormBlocks {
                    ... on Strapi_ComponentFormComponentsTextField {
                      id
                      Required
                      Placeholder
                      Name
                    }
                    ... on Strapi_ComponentFormComponentsTelField {
                      id
                      Required
                      Placeholder
                      Name
                    }
                    ... on Strapi_ComponentFormComponentsDateField {
                      id
                      Required
                      Placeholder
                      Name
                    }
                    ... on Strapi_ComponentFormComponentsNumberField {
                      id
                      Required
                      Placeholder
                      Name
                    }
                    ... on Strapi_ComponentFormComponentsEmailField {
                      id
                      Required
                      Placeholder
                      Name
                    }
                    
                    ... on Strapi_ComponentFormComponentsTextareaField {
                      id
                      Required
                      Placeholder
                      Name
                    }
                    
                    ... on Strapi_ComponentFormComponentsDropDownField {
                      id
                      Required
                      Placeholder
                      Name
                      Values {
                      	... on Strapi_ComponentFormComponentsValues {
                          id
                          Text
                          Value
                        }
                      }
                    }
                  }
                }
              }
              ... on Strapi_ComponentPageComponentsHero {
                  id
                  background_image {
                      url
                  }
                  title
                  subtitle
                  text
              }
              ... on Strapi_ComponentPageComponentsHeroVideo {
                  id
                  background_image {
                      url
                  }
                  title
                  subtitle
                  text
                  video_crop
                  youtube_id

              }
              ... on Strapi_ComponentPageComponentsLogos {
                  id
                  title
                  background_color
                  invert_color
                  extra_padding
                  logos {
                    id
                    alternativeText
                    url
                    width
                    height
                  }
              }
              ... on Strapi_ComponentPageComponentsTextVideoRight {
                  id
                  Title
                  background_image {
                    url
                  }
                  content
                  embed_video
                  video_still {
                    id
                    alternativeText
                    url
                  }
                  strap_title
                  subliminal_text
                  extra_link
              }
              ... on Strapi_ComponentPageComponentsBandLineUp {
                  id
                  include_list
                  exclude_list
              }
              ... on Strapi_ComponentPageComponentsImageLeftTextRight {
                  id
                  Title
                  Content
                  link
                  link_title
                  main_img {
                      id
                      alternativeText
                      url
                  }
                  secondary_img {
                      id
                      alternativeText
                      url
                  }
                  subliminal_message
                  video_embed
                  video_thumbnail {
                    alternativeText
                    url
                  }
              }
              ... on Strapi_ComponentPageComponentsImageRightTextLeft {
                  id
                  Title
                  Content
                  vertical_title
                  primary_img {
                      id
                      alternativeText
                      url
                  }
              }

              ... on  Strapi_ComponentPageComponentsCarousel {
                  id
                  Title
                  subtitle
                  background_color
                  read_more_link
                  read_more_text
                  background_img {
                      url
                  }
                  carousel_item {
                      id
                      title
                      text
                      link
                      image {
                          url,
                          alternativeText
                      }
                  }
              }
              ... on  Strapi_ComponentPageComponentsBookJamHot {
                  id
                  Title
                  vertical_title
                  Content
                  book_image {
                      alternativeText
                      url
                      width
                      height
                  }
                  book_title
                  book_subtitle
                  book_link_text
                  book_link
                  full_width_video_embed
                  video_still {
                    id
                    alternativeText
                    url
                  }
              }
              ... on  Strapi_ComponentPageComponentsVideoCarousel {
                  id
                  Title
                  video_item {
                      id
                      embed_code
                      video_still {
                        id
                        alternativeText
                        url
                      }
                  }
              }
              ... on  Strapi_ComponentPageComponentsSonglist {
                  id
                  include_list
                  exclude_list
              }
              ... on  Strapi_ComponentPageComponentsGallery {
                  id
                  Title
                  images {
                      id
                      url
                      width
                      height
                      alternativeText
                  }
              }
              ... on  Strapi_ComponentPageComponentsVideoCarousel {
                  id
                  Title
                  video_item {
                      id
                      embed_code
                  }
              }
              ... on  Strapi_ComponentPageComponentsReviews {
                  id
                  Title
                  vertical_title
                  content
                  reviews_link_text
                  reviews_link
                  background_image {
                      id
                      url
                      width
                      height
                      alternativeText
                  }
                  reviews {
                    id
                    content
                  }
              }
              ... on  Strapi_ComponentPageComponentsPerformanceMap {
                  id
                  Title
                  Content
                  vertical_title
                  map_title
                  map_img {
                      id
                      url
                      width
                      height
                      alternativeText
                  }
                  location_list {
                      id
                      location
                      link
                      image {
                          id
                          url
                          width
                          height
                          alternativeText
                      }
                  }
              }

              ... on Strapi_ComponentPageComponentsHeroImage {
                  id
                  image {
                      url
                  }
                  Title
              }
              ... on Strapi_ComponentPageComponentsFullWidthBook {
                id
                book_button_link
                book_button_text
                book_subtitle
                book_title
                image {
                  alternativeText
                  height
                  url
                  width
                }
                social_title
              }
              ... on Strapi_ComponentPageComponentsFullReviews {
                id
                Title
                event_type {
                    name
                }
                vertical_title
                background_image {
                    id
                    url
                }
              }
              ... on Strapi_ComponentPageComponentsGalleryMasonry {
                  id
                  images {
                      id
                      url
                      alternativeText
                      width
                      height
                  }
              }
              ... on Strapi_ComponentPageComponentsGalleryCollage {
                  id
                  VerticalTitle,
                  link,
                  link_title
                  Images {
                      id
                      url
                      alternativeText
                      width
                      height
                  }
              }
              ... on Strapi_ComponentPageComponentsProductionOptions {
                id
                VerticalTitle
                Subtitle
                Title
                BottomSectionPO {
                  Column2
                  Column3
                  Content
                  Title
                  Image {
                    alternativeText
                    url
                    height
                    width
                  }
                }
                TopSectionPO {
                  Title
                  OptionColumnPo {
                    Content
                    Subtile
                    Title
                    Image {
                      alternativeText
                      height
                      width
                      url
                    }
                  }
                }
              }
              ... on Strapi_ComponentPageComponentsVideoThumbText {
                id
                Title
                Content
                VideoEmbed
                VideoThumbnail {
                  alternativeText
                  height
                  url
                  width
                }
                BackgroundImage{
                  alternativeText
                  height
                  url
                  width
                }
              }
              ... on Strapi_ComponentPageComponentsTwoColumnTitleText {
                id
                Column1Content
                Column1Title
                Column2Content
                Column2Title
                Subtitle
                Title
                VerticalTitle
              }
              ... on Strapi_ComponentPageComponentsTextImageText {
                id
                Column1Content
                Column1Title
                Column2Content
                Column2Title
                Title
                VerticalTitle
                Image {
                  alternativeText
                  height
                  url
                  width
                }
              }
              ... on Strapi_ComponentPageComponentsTextBlock {
                id
                Content
                Title
                VerticalTitle
                BackgroundImage {
                  alternativeText
                  url
                  height
                  width
                }
                BackgroundColour
                InvertTextColour
              }
              ... on Strapi_ComponentPageComponentsFullPerformanceMap {
                id
                vertical_title
                Title
                Content
                MapLocations {
                  Region
                  location_list {
                    id
                    event_type {
                      EventTitle
                      id
                      location_list {
                        id
                        image {
                          alternativeText
                          width
                          url
                          height
                        }
                        link
                        location
                      }
                    }
                  }
                  id
                }
                map_img {
                  alternativeText
                  url
                  width
                  height
                }
              }
              ... on Strapi_ComponentPageComponentsVideoCollection {
                id
                Content
                Title
                VideoRow {
                  id
                  Content
                  Title
                  VideoEmbed
                  VideoThumbnail {
                    alternativeText
                    url
                    width
                    height
                  }
                  VideoTitle
                }
              }
              ... on Strapi_ComponentPageComponentsVideoOffsetBlock {
                id
                VideoBlock {
                  id
                  Subtitle
                  Title
                  VerticalTitle
                  VideoEmbed
                  VideoThumbnail {
                    alternativeText
                    height
                    url
                    width
                  }
                  VideoTitle
                }
              }
          }
        }
    }
  }
`

export const getComponent = (component, index) => {
    switch(component.__typename.replace('Strapi_ComponentPageComponents', '')) {
      
        case 'Hero': return <Hero key={`${component.id}-${index}`} {...component} />;
        case 'HeroVideo': return <Hero key={`${component.id}-${index}`} {...component} />;
        case 'Logos': return <LogoRow key={`${component.id}-${index}`} {...component} />;
        case 'TextVideoRight': return <AboutUs key={`${component.id}-${index}`} {...component} />;
        case 'BandLineUp': return <BandLineUp key={`${component.id}-${index}`} {...component} />;
        case 'ImageRightTextLeft': return <ImageRightText key={`${component.id}-${index}`} {...component} />;
        case 'ImageLeftTextRight': return <ImageLeftText key={`${component.id}-${index}`} {...component} />;
        case 'Carousel': return <Carousel key={`${component.id}-${index}`} {...component} />;
        case 'BookJamHot': return <BookJamHot key={`${component.id}-${index}`} {...component} />;
        case 'VideoCarousel': return <VideoCarousel key={`${component.id}-${index}`} {...component} />;
        case 'Songlist': return <Songlist key={`${component.id}-${index}`} {...component} />;
        case 'Gallery': return <Gallery key={`${component.id}-${index}`} {...component} />;
        case 'GalleryCollage': return <GalleryCollage key={`${component.id}-${index}`} {...component} />;
        case 'PerformanceMap': return <PerformanceMap key={`${component.id}-${index}`} {...component} />;
        case 'Reviews': return <Reviews key={`${component.id}-${index}`} {...component} />;
        case 'FullWidthBook': return <FullWidthBook key={`${component.id}-${index}`} {...component} />;
        case 'HeroImage': return <HeroImage key={`${component.id}-${index}`} {...component} />;
        case 'FullReviews': return <FullReviews key={`${component.id}-${index}`} {...component} />;
        case 'ProductionOptions': return <ProductionOptions key={`${component.id}-${index}`} {...component} />;
        case 'GalleryMasonry': return <GalleryMasonry key={`${component.id}-${index}`} {...component} />;
        case 'TextBlock': return <TextBlock key={`${component.id}-${index}`} {...component} />;
        case 'TextImageText': return <TextImageText key={`${component.id}-${index}`} {...component} />;
        case 'VideoCollection': return <VideoCollection key={`${component.id}-${index}`} {...component} />;
        case 'FullPerformanceMap': return <FullPerformanceMap key={`${component.id}-${index}`} {...component} />;
        case 'VideoOffsetBlock': return <VideoOffset key={`${component.id}-${index}`} {...component} />;
        case 'TwoColumnTitleText': return <TwoColumnTitle key={`${component.id}-${index}`} {...component} />;
        case 'VideoThumbText': return <VideoThumb key={`${component.id}-${index}`} {...component} />;
        case 'ContactBlock': return <ContactBlock key={`${component.id}-${index}`} {...component} />;
        default: return <div key={`-${index}`}>{component.__typename.replace('Strapi_ComponentPageComponents', '')} Not Properly Hooked up</div>;
    }
}

const Page = ({ data }) => {
    const {page} = data.strapi;
    const {
        meta_description,
        meta_robots,
        og_fb_image,
        og_twitter_image,
        slug,
        Title
    } = page;
  return (
    <>
        <Helmet
            title={Title}
            link={[
                {
                  rel: "stylesheet",
                  href: "https://fonts.googleapis.com/css?family=Teko",
                },
                {
                  rel: "stylesheet",
                  href: "https://fonts.googleapis.com/css?family=Oswald",
                }
            ]}
            meta={[
                {
                name: `description`,
                content: meta_description,
                },
                {
                property: `og:title`,
                content: Title,
                },
                {
                    property: 'robots',
                    content: meta_robots
                },
                {
                property: `og:description`,
                content: meta_description,
                },
                {
                property: `og:type`,
                content: `website`,
                },
                {
                property: `og:url`,
                content: `http://jamhot.band/${slug ? slug: ''}`,
                },
                {
                property: `og:image`,
                content: og_fb_image?.url,
                },
                {
                  property: `og:image:width`,
                  content: 1200,
                },
                {
                  property: `og:image:height`,
                  content: 630,
                },
                {
                name: `twitter:card`,
                content: `summary_large_image`,
                },
                {
                name: `twitter:title`,
                content: Title,
                },
                {
                name: `twitter:description`,
                content: meta_description,
                },
                {
                property: `twitter:url`,
                content: `http://jamhot.band/${slug}`,
                },
                {
                property: `twitter:image`,
                content: og_twitter_image?.url,
                },
                {
                  property: `twitter:image:width`,
                  content: 1200,
                },
                {
                  property: `twitter:image:height`,
                  content: 630,
                },
            ].concat()}
        >
        </Helmet>
        <Layout>
            {page.Content.map((component, index) => getComponent(component, index))}
        </Layout>
    </>
  )
}

export default Page
