import React from "react";
import ReactMarkdown from "react-markdown";
import LazyLoad from 'react-lazyload';
import './text-block.css'

const TextBlock = (props) => {
    const {
        Title,
        Content,
        BackgroundColour,
        BackgroundImage,
        InvertTextColour
    } = props;

    let styles = {
        backgroundColor: `#${BackgroundColour}`
    };

    if (BackgroundImage && BackgroundColour) {
        styles.backgroundImage = 'none'
    } else if (BackgroundImage) {
        styles.backgroundImage =  `url(${BackgroundImage?.url.replace('https://res.cloudinary.com/dfilm3vuy/image/upload/', 'https://res.cloudinary.com/dfilm3vuy/image/upload/f_auto,q_auto/')})`
    }

    return (
        <div className={`${InvertTextColour ? '': 'inverted' } text-block`} style={styles}>
            <div className="container">
                <span className="vertical-title" dangerouslySetInnerHTML={{__html:Title}} />
                <h2 className="text-block__title" dangerouslySetInnerHTML={{__html:Title}} />
                <ReactMarkdown source={Content} />
            </div>
        </div>
    )};

export default TextBlock;