import React from "react";
import LazyLoad from 'react-lazyload';
import './gallery.css';
const arrowLeft = require('../../images/icons/gallery-arrow-left.png');
const arrowRight = require('../../images/icons/gallery-arrow-right.png');

class Gallery extends React.Component {;
    constructor(props) {
        super(props);

        this.state = {
            curPos: 0,
            hideTitle: false
        };

        this.curIndex = 0;
    }

    moveLeft = () => {
        if (this.curIndex > 0) {
            this.curIndex--;
        } else {
            this.curIndex = this.props.images.length - 1;
        }

        this.calculateMove();
    }

    moveRight = () => {
        if (this.props.images.length > this.curIndex + 1) {
            this.curIndex++;
        } else {
            this.curIndex = 0;
        }

        this.calculateMove();
    }

    calculateMove = () => {
        const width =  document.querySelector('.gallery__images-wrapper').getBoundingClientRect().width;
        this.setState({
            curPos: -this.curIndex * width,
            hideTitle: true
        });
    }

    render() {
        const {
            Title,
            images
        } = this.props;
    
        return (
            <div className="gallery">
                <div className="container">
                    <span className="vertical-title">{Title}</span>
                    <div className="gallery__images-wrapper">
                        <ul className="gallery__images" style={{transform: `translateX(${this.state.curPos}px)`}}>
                            {images.map(img => (
                                <li key={img.id}>
                                    <img src={`${img.url}`} alt={img.alternativeText} width={img.width} height={img.height} />
                                </li>
                            ))}
                        </ul>
                        <h2 className="gallery__title" style={{display: this.state.hideTitle ? 'none': ''}}>{Title}</h2>
                        <button onClick={()=>this.moveLeft()} className="gallery__button">
                            <img src={arrowLeft} alt="Arrow Left" />
                        </button>
                        <button onClick={()=> this.moveRight()} className="gallery__button gallery__button--right">
                            <img src={arrowRight}  alt="Arrow Right" />
                        </button>
                    </div>
                </div>
            </div>
        );
    }
} 
export default Gallery;
