import React from "react";
import './full-reviews.css';
import { StaticQuery, graphql } from "gatsby";
import ReviewCard from "../review-card/review-card";
import Masonry from 'react-masonry-component';
import Select from 'react-select';
import LazyLoad from 'react-lazyload';

const fiveStars = require('../../images/icons/5stars.png');

const FullReviews = (props) => {
    const {
        id,
        Title,
        event_type,
        vertical_title,
        background_image,
        invert_color
    } = props;

    let selectedEvent = {value: event_type?.name, label: event_type?.name};
    let masonry;

    const selected = (select) => {
        console.log({select})
        selectedEvent = select;
        document.querySelectorAll('.review-card').forEach(card => {
            card.style.display = card.dataset.event === selectedEvent.value ? 'block' : 'none';

            if (selectedEvent.value === '') {
                card.style.display = 'block';
            }
        });

        masonry.layout();
    }

    if(typeof window !== 'undefined' && event_type) {
        setTimeout(()=> {
            selected(selectedEvent);
        }, 0)
    }

    return (
        <StaticQuery
            query={graphql`
                query {
                    strapi {
                        reviews {
                            id
                            content
                            event_type {
                                name
                            }
                        }
                        eventTypes {
                          id
                          name
                        }
                    }
                }
            `}
            render={
                data => {
                    const {reviews, eventTypes} = data.strapi;

                    let reviewsLength = reviews.filter(review => review.event_type.name === event_type?.name);

                    const options = [
                        {value: '', label: 'Filter by Event Type...' },
                        {value: '', label: 'All Reviews', selected: true }
                    ].concat(eventTypes.map(event => ({label: event.name, value: event.name})))
                    return (
                        <div className={`full-reviews `}>
                            <LazyLoad>
                            <div className="full-reviews__header" style={{backgroundImage:`url(${background_image?.url.replace('https://res.cloudinary.com/dfilm3vuy/image/upload/', 'https://res.cloudinary.com/dfilm3vuy/image/upload/f_auto,q_auto/')})`}}>
                                <div className="container full-reviews__container">
                                    <h2 className="full-reviews__title">
                                        <em>{reviewsLength.length ? reviewsLength.length : reviews.length}</em>&nbsp;
                                        {Title}
                                    </h2>
                                    <p>Average rating: </p>
                                    <p><img src={fiveStars} className="full-reviews__five" alt="Five Stars" /></p>
                                    <p className="full-reviews__rating">5 out of 5 stars</p>
                                    <div className="full-reviews__buttons">
                                        <span>
                                            <Select 
                                                value={(event_type || selectedEvent.value) && selectedEvent}
                                                className="select"
                                                classNamePrefix="select"
                                                options={options}
                                                placeholder='Filter by Event Type...'
                                                onChange={selected}
                                            />
                                        </span>
                                        <span>
                                            <button onClick={() => selected({value:''})} className="button">View all reviews</button>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            </LazyLoad>
                            
                            <div className="full-reviews__content">
                                <div className="container full-reviews__container">
                                    <span className="vertical-title">{vertical_title}</span>
                                    <Masonry 
                                        className={'full-reviews__reviews masonry'}
                                        ref={function(c) {masonry = masonry || c.masonry;}}
                                    >
                                    {reviews.map(review => (
                                        <ReviewCard {...review} key={review.id} />
                                    ))}
                                    </Masonry>
                                </div>
                            </div>
                        </div>
                    )
                }
            }
            />
    );
}

export default FullReviews;