import React from "react";
import LazyLoad from 'react-lazyload';
import './video-offset.css';
const play = require('../../images/icons/play.png');

class VideoOffset extends React.Component {
    player = {};
    videos = [];

    waitTilYouTube = (el, videoCode) => {
        const _this = this;
        setTimeout(() => {
            if(window.YT) {
                window.YT.ready(() => {
                    _this.videos.forEach(video => {
                        _this.player[video.id] = new window.YT.Player(video.el, {
                            videoId: video.videoCode
                        });
                        _this.setState({
                            hideThumbnail: {
                                [video.id]: false,
                                ...this.state.hideThumbnail
                            } 
                        })
                    });
                });
            } else {
                _this.waitTilYouTube(el, videoCode);
            }
        }, 150);
    }

    constructor(props) {
        super(props);
        this.state = {
            hideThumbnail: {}
        }
    }

    componentDidMount() {
        this.waitTilYouTube();
    }

    hideThumb = (id) => {
        this.setState({hideThumbnail: {
            ...this.state.hideThumbnail,
            [id]: true
        }})
        this.player[id].playVideo();
    }

    setupYT = (el) => {
        if(el && el.dataset.videoCode) {
            if(this.videos.filter(video => video.id === el.dataset.id).length) this.videos = [];
            const videoCode = el.dataset.videoCode.match(/src="(.*?)"/g)[0].replace(/src=/gm, "").replace('https://www.youtube.com/embed/','');
            this.videos.push({
                id: el.dataset.id,
                videoCode: JSON.parse(videoCode),
                el
            });
        }
    }

    render() {
        const {
            VideoBlock
        } = this.props;

        return (
            <>
                {VideoBlock.map(video => (
                    <div className="video-offset" key={video.id}>
                        <div className="container">
                            <span className="vertical-title">{video.VerticalTitle}</span>
                            <h2>{video.Title}</h2>
                            <h3>{video.Subtitle}</h3>
                            <div className="video-offset__container" onClick={() => (video.VideoEmbed && video.VideoThumbnail) && this.hideThumb(video.id)}>
                                {!this.state.hideThumbnail[video.id] && (
                                <div className="video-thumbnail">
                                    {(video.VideoEmbed && video.VideoThumbnail) && <img src={play} className="video-btn" />}
                                    <LazyLoad><img src={video.VideoThumbnail?.url.replace('https://res.cloudinary.com/dfilm3vuy/image/upload/', 'https://res.cloudinary.com/dfilm3vuy/image/upload/f_auto,q_auto/')} alt={video.VideoThumbnail?.alternativeText} /></LazyLoad>
                                </div>
                                )}
                                <div className="video-carousel__item">
                                    <div data-id={video.id} ref={el => this.setupYT(el)}  data-video-code={video.VideoEmbed} ></div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </>
        )};
}

export default VideoOffset;