import React from "react";
import LazyLoad from 'react-lazyload';
import './video-carousel.css';
const play = require('../../images/icons/play.png');

class VideoCarousel extends React.Component {
    player = {};
    videos = [];

    waitTilYouTube = (el, videoCode) => {
        const _this = this;
        setTimeout(() => {
            if(window.YT) {
                window.YT.ready(() => {
                    _this.videos.forEach(video => {
                        _this.player[video.id] = new window.YT.Player(video.el, {
                            videoId: video.videoCode
                        });
                        _this.setState({
                            hideThumbnail: {
                                [video.id]: false,
                                ...this.state.hideThumbnail
                            } 
                        })
                    });
                });
            } else {
                _this.waitTilYouTube(el, videoCode);
            }
        }, 150);
    }

    constructor(props) {
        super(props);
        this.state = {
            hideThumbnail: {},
            currPos: 0,
            currTranslation: 0
        }

        this.carousel = React.createRef();

    }

    componentDidMount() {
        this.waitTilYouTube();
    }

    hideThumb = (id) => {
        this.setState({hideThumbnail: {
            ...this.state.hideThumbnail,
            [id]: true
        }})
        this.player[id].playVideo();
    }

    setupYT = (el) => {
        if(el && el.dataset.videoCode) {
            if(this.videos.filter(video => video.id === el.dataset.id).length) this.videos = [];
            const videoCode = el.dataset.videoCode.match(/src="(.*?)"/g)[0].replace(/src=/gm, "").replace('https://www.youtube.com/embed/','');
            this.videos.push({
                id: el.dataset.id,
                videoCode: JSON.parse(videoCode),
                el
            })
        }
    }

    moveLeft = () => {
        this.setState({
            currPos: this.state.currPos - 1,
            currTranslation: -this.carousel.current.offsetWidth * (this.state.currPos - 1)
        });
    }

    moveRight = () => {
        this.setState({
            currPos: this.state.currPos + 1,
            currTranslation: -this.carousel.current.offsetWidth * (this.state.currPos + 1)
        });
    }

    moveTo = (idx) => {
        this.setState({
            currPos: idx,
            currTranslation: -this.carousel.current.offsetWidth * idx
        });
    }

    render() {
        const {
            Title,
            video_item
        } = this.props;

        const isSingle = video_item.length === 1;
             
        return (
            <div className="video-carousel">
                <div className="container video-carousel__vertical-container">
                    <h2 className="vertical-title">{Title}</h2>
                </div>
                <div className="container video-carousel__container">
                    <div ref={this.carousel} className="video-carousel__inner-container">
                        <div className="video-carousel__inner" style={{transform: `translateX(${this.state.currTranslation}px)`}}>
                            {video_item.map(video => (
                                <div key={video.id} className="video-carousel__item-container" onClick={() => this.hideThumb(video.id)}>
                                    {!this.state.hideThumbnail[video.id] && (
                                    <div className="video-thumbnail">
                                        {(video.embed_code && video.video_still) && <img src={play} className="video-btn" />}
                                        <img src={video.video_still?.url.replace('https://res.cloudinary.com/dfilm3vuy/image/upload/', 'https://res.cloudinary.com/dfilm3vuy/image/upload/f_auto,q_auto/')} alt={video.video_still?.alternativeText} />
                                    </div>
                                    )}
                                    <div className="video-carousel__item">
                                        <div data-id={video.id} ref={el => this.setupYT(el)}  data-video-code={video.embed_code} ></div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {!isSingle && (
                <div className="container video-carousel__button-container">
                    <div className="video-carousel__links">
                        {video_item.map((video, idx) => (
                            <button onClick={() => this.moveTo(idx)} className={`${this.state.currPos == idx && 'active'}`} key={video.id}>{idx + 1}</button>
                        ))}
                    </div>
                    <div className="video-carousel__arrows">
                        <button disabled={this.state.currPos === 0} onClick={() => this.moveLeft()} className="video-carousel__arrow-left video-carousel__arrow">Move left</button>
                        <button disabled={this.state.currPos === video_item.length - 1} onClick={() => this.moveRight()} className="video-carousel__arrow-right video-carousel__arrow">Move right</button>
                    </div>
                </div>
                )}
            </div>
        );
    }
}

export default VideoCarousel;