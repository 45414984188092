import React from "react";
import ReactMarkdown from "react-markdown"
import ReviewCard from "../review-card/review-card";
import './reviews.css';

const Reviews = (props) => {
    const {
        Title,
        vertical_title,
        content,
        reviews_link_text,
        reviews_link,
        background_image,
        reviews
    } = props;

    console.log('Reviews', props)

    return (
        <div className="reviews" style={{backgroundImage: `url(${background_image?.url.replace('https://res.cloudinary.com/dfilm3vuy/image/upload/', 'https://res.cloudinary.com/dfilm3vuy/image/upload/f_auto,q_auto/')})`}}>
            <div className="container">
                <div className="reviews__content">
                    <h2 className="reviews__title" dangerouslySetInnerHTML={{__html:Title}}></h2>
                    <ReactMarkdown
                        source={content}
                    />
                    <ul className="reviews__list">
                        {reviews.map(review => (
                            <li>
                                <ReviewCard content={review.content} />
                            </li>
                        ))}
                    </ul>

                    <a href={reviews_link} className="button">{reviews_link_text}</a>
                </div>
                <span className="vertical-title reviews__vertical">{vertical_title}</span>
                <span className="reviews__subliminal">Reviews</span>
            </div>
        </div>
    );
}

export default Reviews;