import React  from 'react';

const DropDownField = (props) => (
    <div className='contact-block__form-row'>
        <label className='contact-block__form-inline-label'>{props.Placeholder}:</label>
        <select required={props.Required} placeholder={props.Placeholder+ '+'} name={props.Name}>
            <option disabled={true}>Please choose...</option>
            {props.Values.map(option => (
                <option key={option.id} value={option.Value}>{option.Text}</option>
            ))}
        </select>
    </div>
)

export default DropDownField;