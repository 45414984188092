import React from "react";
import './hero.css';

class Hero extends React.PureComponent {
    player = undefined;
    constructor(props) {
      super(props);
    }

    waitTilYouTube = (el, videoCode) => {
        const _this = this;
        setTimeout(() => {
            if(window.YT) {
                window.YT.ready(() => {
                    _this.player = new window.YT.Player(el, {
                        videoId: videoCode
                    });
                    _this.player.playVideo();
                });
            } else {
                _this.waitTilYouTube(el, videoCode);
            }
        }, 150);
    }

    render() {
        const {
            title,
            text,
            subtitle,
            background_image,
            youtube_id,
            video_crop
        } = this.props;
        
        return (
            <div className="hero" style={{backgroundImage: `url(${background_image?.url.replace('https://res.cloudinary.com/dfilm3vuy/image/upload/', 'https://res.cloudinary.com/dfilm3vuy/image/upload/f_auto,q_auto/')})`}}>
                { youtube_id && (
                <div className={ `hero__video ${video_crop} `}>
                    {/* <iframe src={`https://www.youtube.com/embed/${youtube_id}?&controls=0&playlist=${youtube_id}&autoplay=1&loop=1&mute=1&playsinline=1`}></iframe> */}

                    <div ref={el => this.waitTilYouTube(el, youtube_id)}  data-video-code={youtube_id} ></div>
                </div>
                )}
                <div className="hero__container">
                    <div className="hero__content">
                        <h1 className="hero__title" dangerouslySetInnerHTML={{__html:title}}></h1>
                        <h2 className="hero__subtitle" dangerouslySetInnerHTML={{__html:subtitle}}></h2>
                        <p dangerouslySetInnerHTML={{__html:text}}></p>

                        <p>
                            <a href="/contact" className="hero__form-button">
                                Get Quote!
                            </a>
                        </p>
                    </div>
                    {/* <div className="hero__form">
                        <h3 className="hero__form-title"><strong>Get your free</strong> no obligation quote!</h3>
                        <div id="mc_embed_signup">
                            <form onSubmit={this.handleSubmit} accept-charset="UTF-8" action="https://hq699.infusionsoft.com/app/form/process/e6ceefd8062a352c357d89bc49b6c7de" className="infusion-form hero__form-form validate"  id="inf_form_e6ceefd8062a352c357d89bc49b6c7de" method="POST">
                                <input name="inf_form_xid" type="hidden" value="e6ceefd8062a352c357d89bc49b6c7de" />
                                <input name="inf_form_name" type="hidden" value="Web Form submitted" />
                                <input name="infusionsoft_version" type="hidden" value="1.70.0.507674" />
                                {this.state.error && (<p className="hero__error">{this.state.error}</p>)}
                                <div class="infusion-field hero__form-row">
                                    <label for="inf_field_FirstName">First Name *</label>
                                    <input id="inf_field_FirstName" data-name="name" value={this.state.name} onChange={this.handleChange}  name="inf_field_FirstName" placeholder="First Name *" type="text" />
                                </div>
                                <div class="infusion-field hero__form-row">
                                    <label for="inf_field_Email">Email *</label>
                                    <input id="inf_field_Email" data-name="email" value={this.state.email} onChange={this.handleChange}  name="inf_field_Email" placeholder="Email *" type="text" />
                                </div>
                                <div>
                                    <div>&nbsp;</div>
                                </div>
                                <div class="infusion-submit hero__form-row">
                                    <button class="infusion-recaptcha" id="recaptcha_e6ceefd8062a352c357d89bc49b6c7de" type="submit" className="hero__form-button">Submit</button>
                                </div>
                            </form>
                            <script type="text/javascript" src="https://hq699.infusionsoft.app/app/webTracking/getTrackingCode"></script>
                            <script type="text/javascript" src="https://hq699.infusionsoft.com/resources/external/recaptcha/production/recaptcha.js?b=1.70.0.507674"></script>
                            <script src="https://www.google.com/recaptcha/api.js?onload=onloadInfusionRecaptchaCallback&render=explicit" async="async" defer="defer"></script>
                            <script type="text/javascript" src="https://hq699.infusionsoft.com/app/timezone/timezoneInputJs?xid=e6ceefd8062a352c357d89bc49b6c7de"></script>
                            <script type="text/javascript" src="https://hq699.infusionsoft.com/js/jquery/jquery-3.3.1.js"></script>
                            <script type="text/javascript" src="https://hq699.infusionsoft.app/app/webform/overwriteRefererJs"></script>

                        </div>
                    </div> */}
                </div>
            </div>
        );
    }
}

export default Hero;