import React from "react";
import ReactMarkdown from "react-markdown"
import LazyLoad from 'react-lazyload';
import './image-left-text.css';

const play = require('../../images/icons/play.png');

class ImageLeftText extends React.Component {
    player = undefined;
    constructor(props) {
        super(props);
        this.state = {
            hideThumbnail: false
        }
    }

    hideThumb = (el) => {
        this.setState({hideThumbnail: true})
        console.log(this.player)
        this.player.playVideo();
    }

    setupYT = (el) => {
        if(el && el.dataset.videoCode) {
            const _this = this;
            const videoCode = el.dataset.videoCode.match(/src="(.*?)"/g)[0].replace(/src=/gm, "").replace('https://www.youtube.com/embed/','');
            this.waitTilYouTube(el, videoCode);
        }
    }

    waitTilYouTube = (el, videoCode) => {
        const _this = this;
        setTimeout(() => {
            if(window.YT) {
                window.YT.ready(() => {
                    _this.player = new window.YT.Player(el, {
                        videoId: videoCode
                    });
                });
            } else {
                _this.waitTilYouTube(el, videoCode);
            }
        }, 150);
    }

    render() {
        const {
            Title,
            Content,
            link,
            link_title,
            main_img,
            secondary_img,
            subliminal_message,
            video_embed,
            video_thumbnail
        } = this.props;
        return (
        <div className="image-text">
            <div className="container image-text__container">
                <div className="image-text__img">
                    <img src={main_img?.url.replace('https://res.cloudinary.com/dfilm3vuy/image/upload/', 'https://res.cloudinary.com/dfilm3vuy/image/upload/f_auto,q_auto/')} alt={main_img?.alternativeText} />
                    <img src={secondary_img?.url.replace('https://res.cloudinary.com/dfilm3vuy/image/upload/', 'https://res.cloudinary.com/dfilm3vuy/image/upload/f_auto,q_auto/')} alt={secondary_img?.alternativeText} className="image-text__bottom-img" />
                    {video_embed && 
                    <>
                        <div onClick={() => this.hideThumb()} className="image-text__video-container">
                            {!this.state.hideThumbnail && (
                            <div className="video-thumbnail">
                                <img src={play} className="video-btn" />
                                <LazyLoad>
                                    <img src={video_thumbnail?.url.replace('https://res.cloudinary.com/dfilm3vuy/image/upload/', 'https://res.cloudinary.com/dfilm3vuy/image/upload/f_auto,q_auto/')} alt={video_thumbnail?.alternativeText} />
                                </LazyLoad>
                            </div>
                            )}
                            <div className="image-text__video">
                                <div ref={el => this.setupYT(el)}  data-video-code={video_embed} ></div>
                            </div>
                        </div>
                    </>
                    }
                    
                </div>
                <div className="image-text__text">
                    <h3 className="image-text__title" dangerouslySetInnerHTML={{__html:Title}}></h3>
                    <ReactMarkdown
                        source={Content}
                    />
                    <a href={link} className="button">{link_title}</a>
                </div>
            </div>
        </div>
        )
    }
}
export default ImageLeftText;