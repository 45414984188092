import React from "react";
import ReactMarkdown from "react-markdown"
import LazyLoad from 'react-lazyload';
import './about-us.css';

const bg = require('../../images/home/about-us-bg.jpg');
const play = require('../../images/icons/play.png');
  class AboutUs extends React.Component {
    player = undefined;
    constructor(props) {
        super(props);
        this.state = {
            hideThumbnail: false
        }
    }

    hideThumb = (el) => {
        this.setState({hideThumbnail: true})
        console.log(this.player)
        this.player.playVideo();
    }

    setupYT = (el) => {
        if(el && el.dataset.videoCode) {
            const videoCode = el.dataset.videoCode.match(/src="(.*?)"/g)[0].replace(/src=/gm, "").replace('https://www.youtube.com/embed/','');
            this.waitTilYouTube(el, JSON.parse(videoCode));
        }
    }

    waitTilYouTube = (el, videoCode) => {
        const _this = this;
        setTimeout(() => {
            if(window.YT) {
                window.YT.ready(() => {
                    _this.player = new window.YT.Player(el, {
                        videoId: videoCode
                    });
                });
            } else {
                _this.waitTilYouTube(el, videoCode);
            }
        }, 150);
    }

    render() {
        const {
            Title,
            strap_title,
            content,
            embed_video,
            subliminal_text,
            video_still,
            background_image,
            extra_link
        } = this.props;
        return (
        <div className="about-us">
            <div className="about-us__container">
                <div className="about-us__content">
                    <h2 className="vertical-title">{strap_title}</h2>
                    <div className="about-us__content-flex">
                        <div>
                            <h3 className="about-us__title" dangerouslySetInnerHTML={{__html:Title}}></h3>
                            <ReactMarkdown
                                source={content}
                            />
                            <p>
                                <a className="about-us__link" href={extra_link}>Learn More</a>
                            </p>
                        </div>
                        <span></span>
                    </div>
                    <span className="about-us__strap" dangerouslySetInnerHTML={{__html:subliminal_text}}></span>
                </div>{/*
                */}
                {(video_still || embed_video) && <div onClick={() => this.hideThumb()}  className="about-us__video">
                    {!this.state.hideThumbnail && (
                    <div className="video-thumbnail">
                        {(embed_video && video_still) && <img src={play} className="video-btn" />}
                        <img  src={video_still?.url.replace('https://res.cloudinary.com/dfilm3vuy/image/upload/', 'https://res.cloudinary.com/dfilm3vuy/image/upload/f_auto,q_auto/')} alt={video_still?.alternativeText} />
                    </div>
                    )}
                    <div className="about-us__video-wrapper">
                        <div ref={el => this.setupYT(el)}  data-video-code={embed_video} ></div>
                    </div>
                </div>
                }
            </div>
            <LazyLoad>
            <div className="about-us__video-bg">
                <img src={background_image?.url.replace('https://res.cloudinary.com/dfilm3vuy/image/upload/', 'https://res.cloudinary.com/dfilm3vuy/image/upload/f_auto,q_auto/')} alt={background_image?.alternativeText} className="about-us__video-bg-img" />
                    <span className="about-us__video-strap">
                        "Immense"
                    </span>
            </div>
            </LazyLoad>
        </div>
    )}
}

export default AboutUs;