import React from "react";
import LazyLoad from 'react-lazyload';
import './logo-row.css';
class LogoRow extends React.Component  {
    constructor(props) {
        super(props);
        this.state = {
            animate: false,
            logos: props.logos
        }
        this.list = React.createRef();
    }

    startTimer() {
        if (this.props.logos.length > 6) {
            setInterval(() => {
                this.setState({animate: true});
                setTimeout(() => {
                    const logos = [...this.state.logos];
                    logos.push(logos.shift());
                    this.setState({
                        logos: logos,
                        animate: false
                    });
                }, 500);
            }, 2000);
        }
    }

    componentDidMount() {
        this.startTimer();
    }

    render(){
        const {
            title,
            background_color,
            invert_color,
            extra_padding
        } = this.props;
        return (
        <div className={`trusted-by  ${invert_color ? 'trusted-by--inverted': ''} ${extra_padding ? 'trusted-by--pad-bottom': ''}`} style={{backgroundColor: background_color}}>
            <div className="trusted-by__container container">
                <h2 className="vertical-title">{title}</h2>
                <div className="trusted-by__list-container">
                    <ul ref={this.list} className={`trusted-by__list ${this.state.logos.length < 6 && 'trusted-by__list--flex'} ${this.state.animate ? 'move' : ''}`}>
                        {this.state.logos.map(logo => (
                        <li key={logo.id}><LazyLoad><img src={`${logo.url}`} width={logo.width} height={logo.height} alt={logo.alternativeText} /></LazyLoad></li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
        );
    }
}

export default LogoRow;