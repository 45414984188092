import React from "react";
import ReactMarkdown from "react-markdown";
import LazyLoad from 'react-lazyload';
import './text-image-text.css'

const TextImageText = (props) => {
    const {
        Column1Content,
        Column1Title,
        Column2Content,
        Column2Title,
        Title,
        VerticalTitle,
        Image 
    } = props;

    let styles = {
        backgroundImage: `url(${Image?.url.replace('https://res.cloudinary.com/dfilm3vuy/image/upload/', 'https://res.cloudinary.com/dfilm3vuy/image/upload/f_auto,q_auto/')})`
    };

    return (
        <div className='text-image-text' style={styles}>
            <div className="container">
                <span className="vertical-title" dangerouslySetInnerHTML={{__html:VerticalTitle}} />
                <h2 className="text-image-text__title" dangerouslySetInnerHTML={{__html:Title}} />
                <div className="text-image-text__content">
                    <div>
                        <h3 className="text-block__title" dangerouslySetInnerHTML={{__html:Column1Title}} />
                        <ReactMarkdown source={Column1Content} />
                    </div>
                    <div>
                        <h3 className="text-block__title" dangerouslySetInnerHTML={{__html:Column2Title}} />
                        <ReactMarkdown source={Column2Content} />
                    </div>
                </div>
            </div>
        </div>
    )};

export default TextImageText;