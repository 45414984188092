import React from "react";
import ReactMarkdown from "react-markdown"
import LazyLoad from 'react-lazyload';
import './book-jam-hot.css';
const play = require('../../images/icons/play.png');

class BookJamHot extends React.Component {
    player = undefined;
    constructor(props) {
        super(props);
        this.state = {
            hideThumbnail: false
        }
    }

    hideThumb = (el) => {
        this.setState({hideThumbnail: true})
        console.log(this.player)
        this.player.playVideo();
    }

    setupYT = (el) => {
        if(el && el.dataset.videoCode) {
            const _this = this;
            const videoCode = el.dataset.videoCode.match(/src="(.*?)"/g)[0].replace(/src=/gm, "").replace('https://www.youtube.com/embed/','');
            this.waitTilYouTube(el, JSON.parse(videoCode));
        }
    }

    waitTilYouTube = (el, videoCode) => {
        const _this = this;
        setTimeout(() => {
            if(window.YT) {
                window.YT.ready(() => {
                    _this.player = new window.YT.Player(el, {
                        videoId: videoCode
                    });
                });
            } else {
                _this.waitTilYouTube(el, videoCode);
            }
        }, 150);
    }

    render() {
        const {
            Title,
            vertical_title,
            Content,
            book_image,
            book_title,
            book_subtitle,
            book_link_text,
            book_link,
            full_width_video_embed,
            video_still
        } = this.props;
        return (
            <div className="book-jam-hot">
                <div className="container book-jam-hot__container">
                    <div className="book-jam-hot__video-container" onClick={() => this.hideThumb()}>
                        {!this.state.hideThumbnail && (
                        <div className="video-thumbnail">
                            {(full_width_video_embed && video_still) && <img src={play} className="video-btn" />}
                            <LazyLoad><img src={video_still?.url.replace('https://res.cloudinary.com/dfilm3vuy/image/upload/', 'https://res.cloudinary.com/dfilm3vuy/image/upload/f_auto,q_auto/')} alt={video_still?.alternativeText} /></LazyLoad>
                        </div>
                        )}
                        <div className="book-jam-hot__video">
                            <div ref={el => this.setupYT(el)}  data-video-code={full_width_video_embed} ></div>
                        </div>
                    </div>
                    <div className="book-jam-hot__text">
                        <h2 className="book-jam-hot__title" dangerouslySetInnerHTML={{__html:Title}}></h2>
                        <span className="vertical-title">{vertical_title}</span>
                        <ReactMarkdown
                            source={Content}
                        />
                    </div>
                    <div className="book-jam-hot__book">
                        <h2 className="book-jam-hot__book-title">{book_title}</h2>
                        <h3 className="book-jam-hot__book-subtitle">{book_subtitle}</h3>
                        <a  className="book-jam-hot__book-button button" href={book_link}>{book_link_text}</a>
                    </div>
                </div>
                <div className="book-jam-hot__img-wrap">
                    <LazyLoad><div 
                        className="book-jam-hot__image" style={{backgroundImage: `url(${book_image?.url.replace('https://res.cloudinary.com/dfilm3vuy/image/upload/', 'https://res.cloudinary.com/dfilm3vuy/image/upload/f_auto,q_auto/')})`}}></div></LazyLoad>
                </div>
            </div>
        );
    }
}

export default BookJamHot;