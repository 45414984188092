import React from "react";
import ReactMarkdown from "react-markdown"
import './two-column-title.css';

const TwoColumnTitle = (props) => {
    const {
        Column1Content,
        Column1Title,
        Column2Content,
        Column2Title,
        Subtitle,
        Title,
        VerticalTitle
    } = props;

    return (
        <div className="two-column-title">
            <div className="container">
                <span className="vertical-title">{VerticalTitle}</span>
                <h2 className="two-column-title__title" dangerouslySetInnerHTML={{__html:Title}} />
                <h3 className="two-column-title__subtitle" dangerouslySetInnerHTML={{__html:Subtitle}} />
                <div className="two-column-title__content">
                    <div>
                        <h4 dangerouslySetInnerHTML={{__html:Column1Title}}></h4>
                        <ReactMarkdown
                            source={Column1Content}
                        />
                    </div>
                    <div>
                        <h4 dangerouslySetInnerHTML={{__html:Column2Title}}></h4>
                        <ReactMarkdown
                            source={Column2Content}
                        />
                    </div>
                </div>
            </div>
        </div>
    )};

export default TwoColumnTitle;